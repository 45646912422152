import { shape, string, elementType } from "prop-types";

import { layoutDefaultProps, layoutPropTypes } from "containers";

// TOOD:
// - add propTypes for data fetched from page queries (reuse inside components that share them).

// pageDefaultProps - configure default props for TemplatePageDefault,
export const pageDefaultProps = {
  ...layoutDefaultProps,
  containerProps: {
    className: "page-body",
    component: "article",
    maxWidth: "md",
  },
};

// pagePropTypes - configure propTypes for TemplatePageDefault.
export const pagePropTypes = {
  ...layoutPropTypes,
  data: shape({
    page: shape({
      body: string,
      frontmatter: shape({
        containerProps: shape({
          className: string,
          component: elementType,
        }), // containerProps: https://material-ui.com/api/container/#props
        description: string,
        header: shape({
          context: string,
          heading: string,
        }), // headerProps: /components/header/_config
        seo: shape({
          description: string,
          title: string,
        }),
        title: string,
      }),
    }),
  }),
};
