import React from "react";

import { Header, MDX } from "components";
import { Layout } from "containers";

import { pageDefaultProps, pagePropTypes } from "./config";

// TODO:
// - create MDX component module with Renderer and Provider
//   - to share same styles and config between the two component types

const TemplatePageDefault = ({
  children,
  pageContext: {
    frontmatter: { containerProps, description, header, layout, seo, title },
  },
}) => {
  const layoutProps = {
    seoProps: {
      description,
      title,
      ...seo,
    },
    ...layout,
  };

  const headerProps = {
    containerProps: {
      maxWidth: "md",
      ...header?.containerProps,
    },
    heading: title,
    ...header,
  };

  return (
    <Layout {...layoutProps}>
      <Header {...headerProps} />
      {children && (
        <MDX {...pageDefaultProps?.containerProps} {...containerProps}>
          {children}
        </MDX>
      )}
    </Layout>
  );
};

TemplatePageDefault.defaultProps = pageDefaultProps;
TemplatePageDefault.propTypes = pagePropTypes;

export default TemplatePageDefault;
